@charset 'utf-8';

@import 'settings';
@import 'custom';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

@include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'font-awesome';

@import 'components/building-blocks/marketing-site-content-section';
@import 'components/building-blocks/marketing-site-features';
@import 'components/building-blocks/marketing-site-footer';
@import 'components/building-blocks/marketing-site-hero';
@import 'components/building-blocks/marketing-site-three-up';
//@import 'components/building-blocks/responsive-social-media-section';
@import 'components/building-blocks/topbar-responsive';
@import 'components/building-blocks/read-more';
//@import 'components/building-blocks/testimonial-block-vertical';
//@import 'components/building-blocks/featured-testimonials';
//@import 'components/building-blocks/testimonial-slider';
//@import 'components/building-blocks/product-feature-section';
//@import 'components/building-blocks/pricing-table';
//@import 'components/building-blocks/topbar-center-logo';
//@import 'components/building-blocks/app-feature-section';
//@import 'components/building-blocks/fullscreen-image-slider';
//@import 'components/building-blocks/card-flex-article';
//@import 'components/building-blocks/full-width-testimonial';
//@import 'components/building-blocks/four-up-feature';
//@import 'components/building-blocks/newsletter-subscribe';
//@import 'components/building-blocks/form-icons';
@import 'components/building-blocks/contact-us-section';
@import 'components/mailchimp';

@import 'components/building-blocks/contact-us-section';

@import "alpha";
