$topbar-responsive-bg: #2c3840;
$topbar-responsive-animation-type: fade-in; // or use slide-down or none

.topbar-responsive {
  background: $topbar-responsive-bg;
  padding: 1rem 1.5rem;

  .topbar-responsive-logo {
    color: $white;
    vertical-align: middle;
  }

  .menu {
    background: $topbar-responsive-bg;

    li:last-of-type {
      margin-right: 0;
    }

    a {
      color: $white;
      transition: color 0.15s ease-in;

      &:hover {
        color: lighten($topbar-responsive-bg, 60%);
      }

      @media screen and (max-width: 39.9375em) {
        padding: 0.875rem 0;
      }
    }

    .topbar-responsive-button {
      color: $white;
      border-color: $white;
      border-radius: 5000px;
      transition: color 0.15s ease-in, border-color 0.15s ease-in;

      &:hover {
        color: lighten($topbar-responsive-bg, 60%);
        border-color: lighten($topbar-responsive-bg, 60%);
      }

      @media screen and (max-width: 39.9375em) {
        width: 100%;
        margin: 0.875rem 0;
      }
    }
  }

   @media screen and (max-width: 39.9375em) {
     padding: 0.75rem;

    .top-bar-title {
      position: relative;
      width: 100%;

      span {
        position: absolute;
        right: 0;
        border: 1px solid $white;
        border-radius: 5px;
        padding: 0.25rem 0.45rem;
        top: 50%;
        transform: translateY(-50%);

        .menu-icon {
          margin-bottom: 4px;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity:0;
  } to {
    opacity:1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@include breakpoint(small only) {
  .topbar-responsive-links {
    animation-fill-mode: both;
    animation-duration: 0.5s;
    width: 100%;

    @if $topbar-responsive-animation-type == slide-down {
      animation: slideDown 1s ease-in;
    } @else if $topbar-responsive-animation-type == fade-in {
      animation: fadeIn 1s ease-in;
    } @else {
      animation: none;
    }
  }
}
