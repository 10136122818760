@import url('//cdn-images.mailchimp.com/embedcode/slim-10_7.css');


.mc_embed {
  // width: 70%;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid #fff;
  // border-radius: 20px;

#mc_embed_signup{
  background:#fff;
  clear:left;
  font:14px Helvetica,Arial,sans-serif;
  width:100%;
  &.input.button{
    margin-left: auto;
    margin-right: auto;
  }
  border-radius: 20px;
}

#mc_embed_signup_scroll{
  input {
    margin-left: auto;
    margin-right: auto;
    &.email{
      @extend input;
      width: 80%;
      max-width: 30em;
      margin-bottom: 5px;
    }
    &.button {
      @extend input;
      max-width: 1em;
      width: 50%;
    }
  }
  label {
    text-align: center;
  }
}

}
