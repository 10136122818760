@import url('https://fonts.googleapis.com/css?family=Hind:400,600|Londrina+Solid');

$font-hind: 'Hind', sans-serif;
$font-londrina: 'Londrina', sans-serif;

$body-font-family: $font-hind;

.topbar-responsive-logo, .menu a{
  font-family: $font-londrina;
}

.topbar-responsive-logo {
  font-size: 1.25em;
}


.module .marketing-site-hero-content {
  color: $white;
  @extend .topbar-responsive-logo;
  margin-left: 45%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 5px 10px;

  h1 {
    font-weight: bold;
    font-size: 2em;
    color: rgba(255,255,255,0.75);
  }

  .subheader {
    color: rgba(255,255,255,1);
  }

  @media screen and (min-width: 40em) {
    padding-left: 2%;
  }
  
  @include breakpoint(medium down) {
    margin-left:auto;
    margin-right:auto;
  }

}
 .module blockquote {
   margin-left: auto;
   margin-right: auto;
   width: 60%;
   border-left: 0px;
   color: rgba(255,255,255,1);
   font-weight: bold;
   font-size: 2em;
   text-align: center;
   cite {
     font-size: 1em;
   }
 }

 .module .fa {
   font-size: 3rem;
   color: #1779ba;
   margin-bottom: 1rem;
 }

 .marketing-site-footer-block li{
   list-style-type: none;
 }

 .card-image{
   height: 20vh;
   width: 100%;
 }


/* ============================================================
  SECTIONS
============================================================ */
section.module:last-child {
  margin-bottom: 0;
}

section.module p:last-child {
  margin-bottom: 0;
}
section.module.content {
  //padding: 40px 0;
}
section.module.parallax {
  //height: 600px;
  @extend .show-for-large;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin-top: 10px;
  margin-bottom: 10px;
}
section.module.parallax h1 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 48px;
  line-height: 200px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
}
section.module.parallax-1 {
  background-image: url("/assets/img/bg/church_side.jpg");
}
section.module.parallax-2 {
  background-image: url("/assets/img/bg/church_sign.jpg");
}
section.module.parallax-3 {
  background-image: url("/assets/img/bg/building_flower.jpg");
}
section.module.parallax-4 {
  background-image: url("/assets/img/bg/fog_pict.jpg");
}

.paseo-card {
  text-align: center;
  padding: $global-padding;
}

.feature-main-service {
  text-align: center;
  font-weight: $global-weight-bold;
}

.byline{
  font-weight: 700;
  a {
    color: $white;
  }
}
