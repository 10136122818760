$alpha-color: #1d92cf;

// $hero-height: 60vh;
$body-font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
// $header-font-family: 'Josefin Sans';
// $question-font-family: 'Archivo Black';
$header-font-family: "Archivo Black";

.cn-alpha {
  position: relative;
  font-family: $body-font-family;

  h1,
  h2,
  h3 {
    font-family: $header-font-family;
    color: $alpha-color;
    text-align: center;
  }

  h1 {
    font-size: 4rem;
  }

  &-button.button {
    background-color: $alpha-color;
    font-size: 2rem;
  }

  .logo {
    position: relative;
    top: 5rem;
    left: 5%;
  }

  .content {
    position: relative;
    top: 5rem;
    text-align: center;
    font-family: $header-font-family;
    padding-bottom: 5rem;

    span {
      font-size: 2rem;
      font-weight: bolder;
    }

    h2 {
      font-weight: bold;
      font-size: 3rem;
      color: $alpha-color;
    }

    h1 {
      color: $alpha-color;
      font-weight: bold;
      font-size: 6rem;
    }
    p {
      color: $alpha-color;
      font-weight: bold;
      text-align: justify;
      font-size: 1.2rem;
    }
  }

  &-header {
    .button {
      background-color: $alpha-color;
    }
  }

  &-question {
    text-align: center;
    background-color: $alpha-color;
    width: 100%;
    h1 {
      font-family: $header-font-family;
      font-size: 3rem;
      color: $white;
      padding: 1rem;
    }
  }

  &-video {
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    border: 4px solid $alpha-color;

    video {
      width: 100%;
    }
  }
}
.marketing-site-three-up {
  h2 {
    font-size: 2.5rem;
    font-weight: bolder;
  }

  .fa {
    color: $alpha-color;
  }
}
.marketing-site-content-section {
  border: 4px solid $alpha-color;

  .marketing-site-content-section-block {
    h3 {
      font-size: 2rem;
      font-weight: bold;
      color: $alpha-color;
    }
    p {
      font-size: 1.5rem;
    }
    li {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}

$profile-card-about-text-transform: uppercase;
$profile-card-about-separator-width: 3rem;
$profile-card-about-separator-thickness: 0.125rem;

.profile-card-action-icons {
  color: $alpha-color;
  background-color: $white;
  font-weight: 400;
  margin-bottom: 1.6rem;
  border-radius: 0.125rem;
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.18),
    0 0.125rem 2px rgba(0, 0, 0, 0.36);

  .card-section {
    background-color: inherit;
    padding: 0.5rem;

    .profile-card-header,
    .profile-card-about {
      border-bottom: 0.0625rem solid $medium-gray;
      padding: 0.7rem 1rem;
    }

    .profile-card-header {
      display: flex;
      align-items: flex-start;

      .profile-card-avatar {
        margin-right: 0.5rem;

        .avatar-image {
          border-radius: 50%;
          width: rem-calc(60);
          height: rem-calc(60);
        }
      }

      .profile-card-author {
        flex: 1 0 0;
        margin-top: 0.3rem;
        padding-bottom: 0;

        .author-title,
        .author-description {
          margin-bottom: 0;
        }
      }
    }

    .profile-card-about {
      .about-title {
        text-transform: $profile-card-about-text-transform;
      }

      .separator-left {
        text-align: left;
        @include clearfix;

        &::after {
          position: relative;
          width: $profile-card-about-separator-width;
          border-bottom: $profile-card-about-separator-thickness solid
            $primary-color;
          margin: 0.3rem auto 0;
          margin-left: 0;
        }
      }

      .about-content {
        font-size: 1rem;
        text-align: left;
        color: $alpha-color;
        // margin-bottom: 0;
        p {
          margin-bottom: 1rem;
        }
      }

      .about-skills {
        margin-top: 0.6rem;

        ul.arrow {
          list-style-type: none;
          padding-left: 0;
          margin-left: 0;
          margin-bottom: 0;

          li {
            &:before {
              content: "\f006";
              font-family: "FontAwesome";
              float: left;
              color: $primary-color;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .profile-card-action {
      display: flex;

      .action-area {
        width: 20%;
        border-right: 0.0625rem solid $medium-gray;
        text-align: center;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;

        &:last-of-type {
          border-right: none;
        }

        .action-anchor {
          &.has-tip {
            border-bottom: none;
            cursor: pointer;
          }

          .fa {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.contact-us-section {
  .contact-us-section-left {
    border-color: $alpha-color;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 5rem;
    color: $alpha-color;
  }

  h2,
  h3 {
    font-weight: bold;
    font-size: 2rem;
    text-align: left;
  }

  dl {
    dd {
      padding-left: 1rem;
    }
  }

  ul {
    list-style-type: none;
    li {
      font-size: 1rem;
    }
  }

  label > span {
    font-weight: bold;
    &::after {
      font-style: italic;
      content: " - Required";
    }
  }

  .cn-alpha-button,
  .contactus-field input.button {
    color: $white;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
    background-color: $alpha-color;
  }

  .contactus-form {
    label {
      font-weight: bold;
    }
  }
}
