$marketing-site-hero-height: 65vh;

.marketing-site-hero {
  background: url('/assets/img/hero/pastor_bennet.jpg') fixed right no-repeat;
  height: $marketing-site-hero-height;
  background-size: cover;
  display: flex;
  align-items: center;
  //height: 100vh;

  @media screen and (min-width: 40em) {
    background-position: center center;
  }
}

.marketing-site-hero-content {
  max-width: $global-width;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;

  h1 {
    font-size: 32px;
  }

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 40em) {
    padding-left: 50%;
  }
}
